
import { mapActions, mapGetters, mapMutations } from "vuex";
import offerFilters from "@/apollo/queries/offer/offerFilters";
// import onlyFiltersRequest from "@/apollo/queries/offer/onlyFiltersPrice";
import onlyOfferFiltersNew from "@/apollo/queries/offer/onlyOfferFiltersNew";
import { timezoneOffsetCurrent } from "@/app/variables.js";
import offerUrl from "@/apollo/queries/offer/offerUrl";
import _ from "lodash";
import utm from "@/mixins/utm";
import metrika from "@/mixins/metrika";
import settings from "../mixins/settings";

export default {
  mixins: [utm, metrika],
  data() {
    return {
      snowPosition: { x: 0, y: 0 },
      urlVkPixel:
        "https://top-fwz1.mail.ru/tracker?id=3329909;e=RG%3A/trg-pixel-16077757-1682325999253;_=" +
        Math.random() * new Date()
    };
  },
  watch: {
    $route() {
      if (this.urlValidate && this.reload) {
        this.checkUrl();
      }
      if (this.$route.name == "index") {
        this.onlyFilterRequest({
          url: "/cars"
        });
      }
      this.clearData();
    }
  },
  async fetch() {
    if (this.urlValidate) {
      await this.checkUrl();
    }

    if (this.$route.name == "index") {
      await this.onlyFilterRequest({
        url: "/cars"
      });
    }
  },
  mounted() {

    setTimeout(this.openInteractive, 10000);

    // this.initSnow();
    let dateObj = new Date();
    let timezoneOffset = -(dateObj.getTimezoneOffset() / 60);
    dateObj.setTime(dateObj.getTime() + ((timezoneOffsetCurrent - timezoneOffset) * 60 * 60 * 1000))
    let month = dateObj.getMonth() + 1; //months from 1-12
    let day = dateObj.getDate();
    let hours = dateObj.getHours();


    this.setNight(hours >= 21 || hours < 9);
    if ((month === 12 && day === 31) || (month === 1 && day <= 2)) {
      if (
        //проверка на вермя с 18:00 31 ого
        (month === 12 && day === 31 && hours >= 18) ||
        //проверка на 1ое января
        (month === 1 && day === 1) ||
        //проверка на вермя с до 9:00 2ого
        (month === 1 && day === 2 && hours <= 9)
      ) {
        this.setNight("NY");
      }
    }
  },
  computed: {
    settings() {
      return settings
    },
    ...mapGetters({
      chosen: "filters/filters/chosen",
      isFilterClick: "filters/filters/isFilterClick",
      isOfferClick: "filters/filters/isOfferClick",
      sort: "filters/filters/sort",
      reload: "reload/reload/reload",
      isNight: "settings/settings/isNight",
      settings: "settings/settings/settings",
    }),
    hasRouteTransition() {
      return this.$route.name !== "category-mark-model-car";
    },
    urlValidate() {
      return this.$route.params.category === "cars";
    }
  },
  methods: {
    ...mapMutations({
      setChosen: "filters/filters/SET_CHOSEN",
      resetChosen: "filters/filters/RESET_CHOSEN",
      setComponentCatalog: "filters/filters/SET_COMPONENT_CATALOG",
      setIsFilterClick: "filters/filters/SET_IS_FILTER_CLICK",
      setIsOfferClick: "filters/filters/SET_IS_OFFER_CLICK",
      setLoadingRange: "filters/filters/SET_LOADING_RANGE",
      setModalMenu: "modal/modal-menu/setModalMenu",
      setModalMarks: "modal/modal-marks/setModalMarks",
      setNight: "settings/settings/SET_NIGHT"
    }),
    ...mapActions({
      openModal: "modal/modal-main/openModal",
      request: "filters/filters/request",
      clearData: "modal/modal-choose/clearData",
      closeModal: "modal/modal-main/closeModal"
    }),

    openInteractive() {
      let payload = {
        modal_component: "modal-interactive"
      };
      this.openModal(payload);
    },


    initSnow() {
      if (window) {
        if (!this.$device.isMobile) {
          window.onmousemove = e => {
            const randomItem = arr =>
              arr[Math.floor(Math.random() * (3 - 0) + 0)];
            const color = ["#ff0000", "#00cbff", "#fff"];

            const disance = (p1, p2) => {
              return Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
            };

            if (
              disance(
                { x: e.pageX, y: e.pageY + 10 },
                { x: this.snowPosition.x, y: this.snowPosition.y }
              ) >= 70
            ) {
              const dot = document.createElement("div");
              dot.style.left = e.pageX + "px";
              dot.style.top = e.pageY + 10 + "px";
              dot.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 219.781 219.781" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="m202.314 154.591-22.323-12.888 19.345-11.407a7.5 7.5 0 1 0-7.619-12.921l-26.648 15.713-40.179-23.198 40.172-23.194 26.656 15.711a7.497 7.497 0 0 0 10.269-2.654 7.501 7.501 0 0 0-2.653-10.27L179.986 78.08l22.328-12.891a7.5 7.5 0 0 0-7.5-12.99l-22.323 12.889-.203-22.461c-.037-4.142-3.435-7.489-7.567-7.432a7.499 7.499 0 0 0-7.432 7.567l.279 30.941L117.391 96.9V50.51l26.937-15.224a7.5 7.5 0 0 0-7.381-13.059L117.391 33.28V7.5a7.5 7.5 0 0 0-15 0v25.78L82.835 22.228a7.5 7.5 0 0 0-7.381 13.059l26.936 15.224V96.9L62.213 73.703l.279-30.941a7.499 7.499 0 0 0-7.432-7.567h-.069a7.5 7.5 0 0 0-7.498 7.433l-.203 22.46-22.323-12.889a7.5 7.5 0 0 0-7.5 12.99L39.794 78.08 20.443 89.485a7.501 7.501 0 0 0 7.617 12.924l26.659-15.711 40.173 23.194-40.18 23.198-26.652-15.716a7.501 7.501 0 0 0-10.27 2.651 7.499 7.499 0 0 0 2.651 10.269l19.348 11.408-22.323 12.888a7.5 7.5 0 0 0 7.5 12.99l22.323-12.889.203 22.462a7.5 7.5 0 0 0 7.498 7.433l.069-.001a7.499 7.499 0 0 0 7.432-7.567l-.279-30.942 40.178-23.197v46.391l-26.936 15.224a7.5 7.5 0 0 0 7.381 13.059l19.556-11.052v25.78a7.5 7.5 0 0 0 15 0v-25.78l19.556 11.052a7.498 7.498 0 0 0 10.22-2.839 7.5 7.5 0 0 0-2.839-10.22l-26.937-15.224v-46.39l40.177 23.196-.286 30.941a7.499 7.499 0 0 0 7.43 7.569l.071.001a7.5 7.5 0 0 0 7.498-7.431l.208-22.465 22.326 12.89a7.499 7.499 0 0 0 10.245-2.745 7.5 7.5 0 0 0-2.746-10.245z" fill="currentColor" opacity="1" data-original="currentColor" class=""></path></g></svg>`;
              dot.className = `dot dot${randomItem([1, 2, 3])}`;

              dot.style.color = randomItem(color);
              this.snowPosition.x = e.pageX;
              this.snowPosition.y = e.pageY;
              document.body.appendChild(dot);
              setTimeout(() => document.body.removeChild(dot), 1500);
            }
          };
        } else {
          //   window.onpointermove = e => {
          //     const randomItem = arr =>
          //       arr[Math.floor(Math.random() * (3 - 1) + 1)];
          //     const color = ["#00ff30", "#00cbff", "#fff"];

          //     const snowArray = [];

          //     for (let index = 0; index < 10; index++) {
          //       const dot = document.createElement("div");
          //       dot.style.left = e.pageX + "px";
          //       dot.style.top = e.pageY + 10 + "px";
          //       dot.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 219.781 219.781" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="m202.314 154.591-22.323-12.888 19.345-11.407a7.5 7.5 0 1 0-7.619-12.921l-26.648 15.713-40.179-23.198 40.172-23.194 26.656 15.711a7.497 7.497 0 0 0 10.269-2.654 7.501 7.501 0 0 0-2.653-10.27L179.986 78.08l22.328-12.891a7.5 7.5 0 0 0-7.5-12.99l-22.323 12.889-.203-22.461c-.037-4.142-3.435-7.489-7.567-7.432a7.499 7.499 0 0 0-7.432 7.567l.279 30.941L117.391 96.9V50.51l26.937-15.224a7.5 7.5 0 0 0-7.381-13.059L117.391 33.28V7.5a7.5 7.5 0 0 0-15 0v25.78L82.835 22.228a7.5 7.5 0 0 0-7.381 13.059l26.936 15.224V96.9L62.213 73.703l.279-30.941a7.499 7.499 0 0 0-7.432-7.567h-.069a7.5 7.5 0 0 0-7.498 7.433l-.203 22.46-22.323-12.889a7.5 7.5 0 0 0-7.5 12.99L39.794 78.08 20.443 89.485a7.501 7.501 0 0 0 7.617 12.924l26.659-15.711 40.173 23.194-40.18 23.198-26.652-15.716a7.501 7.501 0 0 0-10.27 2.651 7.499 7.499 0 0 0 2.651 10.269l19.348 11.408-22.323 12.888a7.5 7.5 0 0 0 7.5 12.99l22.323-12.889.203 22.462a7.5 7.5 0 0 0 7.498 7.433l.069-.001a7.499 7.499 0 0 0 7.432-7.567l-.279-30.942 40.178-23.197v46.391l-26.936 15.224a7.5 7.5 0 0 0 7.381 13.059l19.556-11.052v25.78a7.5 7.5 0 0 0 15 0v-25.78l19.556 11.052a7.498 7.498 0 0 0 10.22-2.839 7.5 7.5 0 0 0-2.839-10.22l-26.937-15.224v-46.39l40.177 23.196-.286 30.941a7.499 7.499 0 0 0 7.43 7.569l.071.001a7.5 7.5 0 0 0 7.498-7.431l.208-22.465 22.326 12.89a7.499 7.499 0 0 0 10.245-2.745 7.5 7.5 0 0 0-2.746-10.245z" fill="currentColor" opacity="1" data-original="currentColor" class=""></path></g></svg>`;
          //       dot.className = `dot dot${randomItem([1, 2, 3])}`;
          //       dot.style.color = randomItem(color);
          //       this.snowPosition.x = e.pageX;
          //       this.snowPosition.y = e.pageY;

          //       snowArray.push(dot);
          //     }
          //     for (let dot of snowArray) {
          //       document.body.appendChild(dot);
          //       setTimeout(() => document.body.removeChild(dot), 1500);
          //     }
          //   };
        }
      }
    },
    closeModals() {
      // this.closeStories()
      this.closeModal();
      this.setModalMenu(false);
      this.setModalMarks(false);
    },
    async onlyFilterRequest(assignVariables) {
      // try {
      // 	let response = await this.request({ query: onlyFiltersRequest, variables: assignVariables })

      // 	this.resetChosen()
      // 	this.setChosen({ key: 'priceFrom', value: this.inputFrom })
      // 	this.setChosen({ key: 'priceTo', value: this.inputTo })

      // 	await this.changingFiltersIndexRequest(response.data?.offerFilters);
      // } catch (error) {
      // 	return this.$nuxt.error({ statusCode: 404, message: '404' })
      // }

      try {
        let response = await this.request({
          query: onlyOfferFiltersNew,
          variables: assignVariables
        });
        await this.changingFilters(response.data.offerFilters);

        this.setIsFilterClick(false);
      } catch (error) {
        return this.$nuxt.error({ statusCode: 404, message: "404" });
      }
    },
    async changingFiltersIndexRequest(payload) {
      await this.$store.commit("filters/filters/SET_FILTERS", payload);
      await this.$store.commit("filters/filters/SET_ALL_CHOSEN", {});
    },
    async filterRequest(assignVariables) {
      try {
        let response = await this.request({
          query: offerFilters,
          variables: assignVariables
        });
        await this.changingFilters(response.data.offerFilters);

        // console.log("SET_ALL_CHOSEN", response.data)
        // await this.$store.commit('filters/filters/SET_CHOSEN', { key: 'priceFrom', value: response.data.offerFilters.price[0] })
        // await this.$store.commit('filters/filters/SET_CHOSEN', { key: 'priceTo', value: response.data.offerFilters.price[1] })
        // await this.$store.commit('filters/filters/SET_CHOSEN', { key: 'yearFrom', value: response.data.offerFilters.year[0] })
        // await this.$store.commit('filters/filters/SET_CHOSEN', { key: 'yearTo', value: response.data.offerFilters.year[1] })
        await this.changingOffers(response.data.offers);
        this.setIsFilterClick(false);
      } catch (error) {
        return this.$nuxt.error({ statusCode: 404, message: "404" });
      }
    },
    async changingFilters(payload) {
      await this.$store.commit("filters/filters/SET_FILTERS", payload);
      // await this.$store.commit('filters/filters/SET_ALL_CHOSEN', payload.chosen)
    },
    async changingOffers(payload) {
      await this.$store.commit("filters/filters/SET_OFFERS", payload);
      await this.$store.commit(
        "filters/filters/SET_FILTERED_TOTAL",
        payload.total
      );
      await this.$store.commit("filters/filters/SET_LOADING", false);
    },
    async checkUrl() {
      if (!this.isFilterClick) {
        await this.setLoadingRange(true);
      }
      // Если клик по объявлению, сразу показываем компонент объявления
      if (this.isOfferClick) {
        this.setComponentCatalog("car");
        this.setIsOfferClick(false);
        return;
      }
      await this.$store.commit("filters/filters/SET_LOADING", true);
      // всегда компонент каталога
      await this.setComponentCatalog("catalog-used");

      // // Запрос на определение что это, когда 4 элемента в урл и нет клика по фильтру
      // if (this.$route.params.car && !this.isFilterClick) {
      // 	try {
      // 		let response = await this.request({ query: offerUrl, variables: { url: this.$route.path } })
      // 		let typeName = response.data.offerUrl.__typename
      // 		let assignVariables = response.data.offerUrl
      // 		console.log('assignVariables',assignVariables)
      // 		let queries = {
      // 			engine_type_id_array: this.$numberToArray(this.$route.query.engine_type_id_array),
      // 			gearbox_id_array: this.$numberToArray(this.$route.query.gearbox_id_array),
      // 			drive_type_id_array: this.$numberToArray(this.$route.query.drive_type_id_array),
      // 			body_type_id_array: this.$numberToArray(this.$route.query.body_type_id_array),
      // 			price_from: Number(this.$route.query.price_from),
      // 			price_to: Number(this.$route.query.price_to),
      // 			year_from: Number(this.$route.query.year_from),
      // 			year_to: Number(this.$route.query.year_to),
      // 			sort: this.$route.query.sort || this.sort,
      // 			page: Number(this.$route.query.page) || 1,
      // 			limit: 9
      // 		}
      // 		delete assignVariables.__typename;
      // 		if (typeName === 'OfferUrlFilterPaginationType') {
      // 			// Если это результат для фильтра, отправляем запрос
      // 			await this.filterRequest(this._.pickBy({ ...assignVariables, ...queries }))
      // 		} else if (typeName === 'OfferUrlType') {
      // 			// Если это авто, просто показываем компонент, запрос отправится в компоненте
      // 			this.setComponentCatalog('car')
      // 		}
      // 	} catch (e) {
      // 		this.$nuxt.error({ statusCode: 404 })
      // 	}
      // }
      // Запрос на определение что это, когда 4 элемента в урл и нет клика по фильтру
      if (this.$route.params.car && !isNaN(this.$route.params.car)) {
        this.setComponentCatalog("car");
      } else {
        await this.filterRequest(
          this._.pickBy({
            // TODO очищаю от пустых значений

            // url: 'new',
            url: this.$route.path,

            page: Number(this.$route.query.page) || 1,
            dateFormat: "j F Y года.",
            mark_slug_array: this.$stringToArray(
              this.$route.query.mark_slug_array
            ),
            folder_slug_array: this.$stringToArray(
              this.$route.query.folder_slug_array
            ),
            generation_slug_array: this.$stringToArray(
              this.$route.query.generation_slug_array
            ),
            engine_type_id_array: this.$numberToArray(
              this.$route.query.engine_type_id_array
            ),
            gearbox_id_array: this.$numberToArray(
              this.$route.query.gearbox_id_array
            ),
            drive_type_id_array: this.$numberToArray(
              this.$route.query.drive_type_id_array
            ),
            body_type_id_array: this.$numberToArray(
              this.$route.query.body_type_id_array
            ),
            price_from: Number(this.$route.query.price_from),
            price_to: Number(this.$route.query.price_to),
            year_from: Number(this.$route.query.year_from),
            year_to: Number(this.$route.query.year_to),
            sort: this.$route.query.sort || this.sort,
            limit: 12,
          })
        );
      }
      if (!this.isFilterClick) {
        await this.setLoadingRange(false);
      }
    }
  }
};
